
import {animated} from 'react-spring'
import styled from 'styled-components'

export const BaseContainerWrapper = styled(animated.div)`
    height: 100%;
    display: inline-flex;
	align-content: stretch;
	overflow: hidden;
`;

export const BodyContainer = styled.div({
	width: '100%',
	display: 'inline-flex',
	flexDirection: 'column',
	overflow: 'hidden'
});

export const ContentContainer = styled.div({
	height: '100%',
	display: 'inline-flex',
	position: 'relative',
});