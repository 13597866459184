import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

const Container = styled(animated.div)`
    height: auto;
    width: ${props => props.leftOffset};
    border-right: black 1px solid;
    flex-shrink: 0;
    border-right: solid black 1px;
    position: relative;

    @media screen and (max-width: 500px) {
        width: 13% !important;
    }

`
const Email = styled(animated.span)`
    font-family: Inconsolata, monospace;
    font-weight: normal;
    font-size: 14px;
	text-transform: uppercase;
    transform: rotate(180deg);
    color: black;
    bottom: 0px;
    right: 0;
    writing-mode: vertical-rl;
    position: absolute;
    text-decoration: none;

    opacity: ${props => props.show ? 1 : 0};
	
	@media screen and (max-width: 500px){
		font-size: 14px;
    }

        color: black;
        transition: color 0.2s ease-in, background-color 0.2s ease-in, opacity 0.3s ease-in 0.5s;
        background-color: transparent;
        padding: 6px 2px;

        a {
            padding-top: 7px;
            color: black;
            transition: all 0.2s ease-in;
            text-decoration: none;
        }
    
    &:hover > a{
        color: #E95858;
        text-decoration: underline;
    }

    &:hover{
        color: white;
        background-color: black;
    }

`;

const NavLeft = ( props ) => {

    return (
        <Container {...props}>
            <Email show={props.loadingComplete} >
                emails go here ⟼ 
                <a href="mailto:me@austinarmstrong.dev" target="_blank" rel="noopener noreferrer">
                     me@austinarmstrong.dev
                </a>
            </Email>
        </Container>
    );
};

export default NavLeft;
