import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import * as THREE from 'three';
import { useFrame, useLoader, useUpdate, useThree } from 'react-three-fiber';
import { useHover } from 'react-use-gesture';
import { useSpring as useSpringThree, useTrail as useTrailThree, animated as a, config } from 'react-spring/three';
import { XFadeShader } from '../shaders/xFadeShader';


const cubeConfig = { mass: 6, friction: 70, tension: 800 };

const moveMeshToCorner = (camera) => {
	var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 0, 1), new THREE.Vector3(0, 0, 1));
	const raycaster = new THREE.Raycaster();
	const corner = new THREE.Vector2();
	const cornerPoint = new THREE.Vector3();
	corner.set(-2, 1.5);
	raycaster.setFromCamera(corner, camera);
	raycaster.ray.intersectPlane(plane, cornerPoint);
	const { x, y, z } = cornerPoint.add(new THREE.Vector3(1, 1, -1));
	return [ 0, y, -10 ];
};

const Thing = (props) => {
	const { camera } = useThree();
	const [ hovered, setHover ] = useState(false);
	const [ autoRotate, setAutoRotate ] = useState();
	const [ rotation, setRotation ] = useState([ 0, 0, 0 ]);
	const hover = useCallback(() => setHover(true), []);
	const unhover = useCallback(() => setHover(false), []);
	const [ isFocused, setIsFocused ] = useState(true);
	const [ canvas, setCanvas ] = useState();
	const [ args, setArgs ] = useState({
				uniforms: {
					dispFactor: { type: 'f', value: 0 },
				}
	});

	const ref = useRef();
	//const getRotation = useCallback(() => ref.current.rotataion ? [ref.current.rotataion.x, ref.current.rotation.y, ref.current.rotation.z] : [0.5, 0, 0])
	const [ spring, set ] = useSpringThree(() => ({
		scale: [ 0.01, 0.01, 0.01 ],
		//rotation: getRotation(),
		config: cubeConfig
	}));

	useEffect(
		() => {
			isFocused ? set({ position: [ 0, props.verticalOffset, -4 ] }) : set({ position: moveMeshToCorner(camera) });
		},
		[ isFocused, set, camera, props ]
	);

	useFrame(() => {
		ref.current.rotation.y -= 0.015;
	});

	const [ texture1, texture2, dispTexture ] = useMemo(
		() => {
			const loader = new THREE.TextureLoader();
			return [ loader.load(props.path1), loader.load(props.path2), loader.load(props.path2) ];
		},
		[ props ]
	);

	const [{progress}, setDispFactorSpring ] = useSpringThree(() => ({ 
		progress: hovered ? 1 : 0,
		config: cubeConfig
	}));

	useEffect(() => set({ scale: [ 1, 1, 1 ] }), [ set ]);
	const bindHover = useHover(
		({ hovering }) => {
			set({
				scale: hovering ? [ 1.5, 1.5, 1.5 ] : [ 1, 1, 1 ]
			});
			setDispFactorSpring({ progress: hovering ? 1 : 0 });
		},
		{
			pointerEvents: true
		}
	);

	useEffect(
		() => {
			const canvas = document.createElement('canvas');
			canvas.width = canvas.height = 2048;
			const context = canvas.getContext('2d');
			context.font = `bold 2048px Montserrat`;
			context.textAlign = 'center';
			context.textBaseline = 'middle';
			context.fillStyle = 'white';
			context.fillText(props.character, 1048, 1048);
			const texture = new THREE.CanvasTexture(canvas);
			setCanvas(texture);
		},
		[ props, setCanvas ]
	);

	useEffect(
		() => {
			setArgs({
				uniforms: {
					dispFactor: { type: 'f', value: 0 },
					texture: { type: 't', value: canvas },
					texture2: { type: 't', value: texture1 },
					disp: { type: 't', value: dispTexture }
				},
				fragmentShader: XFadeShader.fragmentShader,
				vertexShader: XFadeShader.vertexShader
			});
		},
		[ canvas, texture1, dispTexture, setArgs ]
  );

	return (
		<a.mesh receiveShadow castShadow
		 			{...bindHover()}
			onHover={hover}
			onUnhover={unhover}
			{...spring}
			ref={ref} position={props.position.interpolate(p => [ 0, -1 + p/100 + props.verticalOffset, -4 ])}
 			onClick={() => setIsFocused((val) => !val)}
 		>
			<boxBufferGeometry attach="geometry" args={[ 1, 1, 1 ]}/>

			<a.shaderMaterial attach="material" args={[ args ]}
				transparent={true} uniforms-dispFactor-value={progress} />
		</a.mesh>
	);
};

export default React.memo(Thing);
