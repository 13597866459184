import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { animated, useSpring, config, useSprings, useTransition } from 'react-spring';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer'
import { ScrollContext } from '../context/scrollStateContext'


const ActiveHeadlineWrapper = styled(animated.div)`
    //width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 61px;
    margin-top: 9px;
    padding-bottom: 5px;
    overflow: hidden;
`;

const HeadlineBottomText = styled(animated.h2)`
    font-family: Inconsolata,monospace;
    font-size: 36px;
    white-space: nowrap;
    margin: 0;
    position: absolute;
    margin-left: ${(props) => (props.offset ? String(props.offset) + 'px' : '16px')};
    z-index: 0;
    margin-top: 16px;
    overflow: hidden;
`;

const HeadlineBackground = styled(animated.div)`
	position: absolute;
	background: black;
	left: 0px;
	right: 0px;
	top: 0px;
	height: 38px;
`;
const HeadlineTransparentMask = styled(animated.div)`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 50%;
    background: transparent;
    z-index: 2;
    overflow: hidden;
    height: 41px;
    display: flex;
`;

Date.prototype.monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

Date.prototype.getMonthName = function () {
	return this.monthNames[this.getMonth()];
};

const HeadlineTopText = styled(animated.h2)`
    font-family: Inconsolata, monospace;
    font-size: 36px;
    white-space: nowrap;
    color: white;
    margin: 0;
    margin-top: 16px;
    margin-left: ${(props) => (props.offset ? String(props.offset) + 'px' : '16px')};
    -webkit-text-stroke: thin;
    -webkit-text-stroke-width: thin;
    position: absolute;
    overflow: visible;
`;

const HeadlineBottomContainer = styled(animated.div)`
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    overflow: hidden;
`;

const ActiveHeadline = (props) => {
	const [availableDate, setAvailableDate] = useState('.');
	useEffect(
		() => {
			const now = new Date();
			if (now.getDate() < 15) {
				setAvailableDate(`late ${now.getMonthName()}, ${now.getFullYear()}.`);
			} else {
				now.setMonth(now.getMonth() + 1);
				setAvailableDate(`early ${now.getMonthName()}, ${now.getFullYear()}.`);
			}
		},
		[setAvailableDate]
	);

	const headlineString = `Austin Armstrong is a software engineer in Brooklyn NY. \
          Currently busy building LLM data enrichment pipelines for pager.xyz.`;
	const headlineDuration = headlineString.length * 150;

	const [animationReady, setAnimationReady] = useState(false);
	const [firstString, { width: headlineWidth }] = useMeasure({ polyfill: ResizeObserver });
	const { top: offset, appHeight } = useContext(ScrollContext);

	const [fullScrolling, setFullScrolling, stopScrolling] = useSprings(9, (index) => ({ from: { opacity: 0 } }), [
		headlineWidth,
		animationReady,
		headlineDuration
	]);

	const backgroundHeight = useTransition(props.loadingComplete, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 }
	})

	useEffect(
		() => {
			if (!props.showPlaceHolder && headlineWidth && !animationReady) {
				setFullScrolling((index) => {
					return {
						from: { opacity: 0 },
						to: { opacity: 1, },
						config: config.gentle,
					}
				});
				setAnimationReady(true);
			}
		},
		[
			headlineWidth,
			setFullScrolling,
			headlineDuration,
			stopScrolling,
			setAnimationReady,
			animationReady,
			props,
			offset, fullScrolling
		]
	);


	const firstWords = 'Austin Armstrong';
	const [testString, setTestString] = useState('');


	useEffect(
		() => {
			if (testString !== firstWords) {
				const id = setInterval(() => setTestString(
					(string) =>
						string !== firstWords
							? string + firstWords[string.length]
							: firstWords), 10);
				return () => clearInterval(id);
			}
		},
		[testString, setTestString]
	);


	const [topTextHeightSpring, setTopTextHeightSpring] = useSpring(() => ({ from: { height: '41px' } }));
	const [bottomTextHeightSpring, setBottomTextHeightSpring] = useSpring(() => ({ from: { height: 100 } }));


	return (
		<ActiveHeadlineWrapper>

			{backgroundHeight.map(({ item, key, props }) =>
				item && <HeadlineBackground style={props} key={"HEADLINE_BACKGROUND#" + key} />
			)}

			{!props.showPlaceHolder && offset && (
				<>
					<HeadlineTransparentMask style={{ ...topTextHeightSpring }}>
						{fullScrolling.map(
							({ opacity }, index) =>
								!props.loadingComplete ? (
									index === 0 && (
										<HeadlineTopText
											ref={firstString}
											key={index}
											style={{
												color: offset.interpolate({
													range: [ 0, appHeight * 2.5, appHeight * 5, appHeight * 7.5, appHeight * 10, appHeight * 12.5, appHeight * 15, appHeight * 17.5, appHeight * 20 ],
		output: [ 'white', '#E8AFC4', '#B3E2F5', '#DEA1FF', '#F6E3CB', '#28C9A1', '#7FC0EF', '#E8EEF2', '#F4F1DE' ]

												}),
												transform: offset.interpolate(o => `translateX(${(-1 * (o/4) + headlineWidth * index + index * 20)}px`),
												opacity
											}}
										>
											{headlineString}
										</HeadlineTopText>
									)
								) : (
										<HeadlineTopText
											ref={firstString}
											key={index}
											style={{
												color: offset.interpolate({
													range: [ 0, appHeight * 2.5, appHeight * 5, appHeight * 7.5, appHeight * 10, appHeight * 12.5, appHeight * 15, appHeight * 17.5, appHeight * 20 ],
		output: [ 'white', '#E8AFC4', '#B3E2F5', '#DEA1FF', '#F6E3CB', '#28C9A1', '#7FC0EF', '#E8EEF2', '#F4F1DE' ]

												}),
												transform: offset.interpolate(o => `translateX(${(-1 * (o/4) + headlineWidth * index + index * 20)}px`),
												opacity
											}}
										>
											{headlineString}
										</HeadlineTopText>
									)
						)}
					</HeadlineTransparentMask>
					<HeadlineBottomContainer
						style={{ height: bottomTextHeightSpring.height.interpolate((height) => height + '%') }}
						{...bottomTextHeightSpring}
					>
						{fullScrolling.map(
							(style, index) =>
								!props.loadingComplete ? (
									index === 0 && (
										<HeadlineBottomText style={{
											...style,
											transform: offset.interpolate(o => `translateX(${(-1 * (o/4) + headlineWidth * index + index * 20)}px`),
										}}>{headlineString}</HeadlineBottomText>
									)
								) : (
										<HeadlineBottomText style={{
											...style,
											transform: offset.interpolate(o => `translateX(${(-1 * (o/4) + headlineWidth * index + index * 20)}px`),
										}}>{headlineString}</HeadlineBottomText>
									)
						)}
					</HeadlineBottomContainer>
				</>
			)}
		</ActiveHeadlineWrapper>
	);
};

export default ActiveHeadline;
