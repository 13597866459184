import React, { useEffect, useState, Suspense } from "react";
import { animated } from "react-spring";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";
import { isMobile } from "react-device-detect";

import NavTop from "./components/navTop";

import { OpenContext, ScrollContext } from "./context/scrollStateContext";

import useScrollStateManager from "./hooks/useScrollStateManager";

import "./App.css";

// Use suspense to code-split out the heavy content tree to get the intro animation up ASAP
//const BaseContainer = React.lazy(() => import("./layout/baseContainer"));

import BaseContainer from './layout/baseContainer'

function App() {
  const [isLoading, setLoading] = useState(true);
  const [loadingTransitionComplete, setLoadingTransitionComplete] =
    useState(false);
  const [appRef, { height: appHeight }] = useMeasure({
    debounce: 1000,
    polyfill: ResizeObserver,
  });

  // Custom hook to manage scroll state springs
  const { stateForScrollContext, openContent, backgroundColorSpring } =
    useScrollStateManager({
      appHeight,
      maxContentSlots: 8,
    });

  // using hard-coded timeout here to best accommodate the animation time + actual asset load time to make a snappy experience.
  useEffect(() => {
    setTimeout(
      () => {
        setLoading(false);
      },
      isMobile ? 1500 : 2500
    );
  }, [setLoading]);

  return (
    <ScrollContext.Provider value={stateForScrollContext}>
      <OpenContext.Provider value={openContent}>
        <animated.div
          className="App"
          ref={appRef}
          style={{ background: backgroundColorSpring }}
        >
          <NavTop
            isLoading={isLoading}
            setLoadingTransitionComplete={setLoadingTransitionComplete}
            showText={true}
          />
          <Suspense fallback={<React.Fragment />}>
            <BaseContainer
              loadingComplete={loadingTransitionComplete}
              isLoading={isLoading}
            />
          </Suspense>
        </animated.div>
      </OpenContext.Provider>
    </ScrollContext.Provider>
  );
}

export default App;
