export default [

    

   ["/images/pager_2.png",
    1,-1.8,-4,24,0,4,0.46221879815,1],
    ["/images/pager_1.png",
    1,0,-6.2,30,1,4,0.46221879815,1],
    ["/images/pager_3.png",
    1,1.8,-4,24,0,4,0.46221879815,1],
    ["/images/pager_4.png",
    1,-1.8,-8.75,24,0,3.8,0.46221879815,1],
    ["/images/pager_5.png",
    1,0,-8.75,24,0,3.8,0.46221879815,1],
    ["/images/pager_6.png",
    1,1.8,-8.75,24,0,3.8,0.46221879815,1],


    ["/images/saturn_1.png",
    2,0,-4,27,-0.4,3.6,1.6890625,1],
    ["/images/saturn_3.webp",
    2,-1.5,-8,27,-0.2,4,0.46221879815,1],
    ["/images/saturn_2.webp",
    2,0,-11,30,0.8,4,0.46221879815,1],
    ["/images/saturn_4.webp",
    2,1.5,-8.5,27,-0.2,4,0.46221879815,1],
    ["/images/saturn_5.webp",
    2,-1.5,-12.5,27,-0.2,4,0.46221879815,1],
    ["/images/saturn_6.webp",
    2,1.5,-13,27,-0.2,4,0.46221879815,1],
    
    ["/images/maude_desktop_home.png",
    3,0,-4,24,0,.75,11.755,7],
    ["/images/maude_desktop_rise.png",
    3,1.5,-10,30,1.4,.55,8.16,7],
    ["/images/maude_mobile_home.png",
    3,1.5,-8,40,1.75,.675,3,5.333],
    ["/images/maude_mobile_blog.png",
    3,2.3,-22,45,.85,.675,3,5.333],
    ["/images/maude_desktop_blog.png",
    3,-.9,-13,27,.75,.675,9.286,7],
    ["/images/maude_mobile_product_rise_2.png",
    3,-3.1,-8,25,1,.55,3,5.333],
    ["/images/maude_mobile_product_rise_1.png",
    3,-1.5,-13,35,1.5,.625,3,5.333],
    ["/images/atomata_dashboard.png",
    4,-1,-4,24,1.3,.75,6.607,4],
    ["/images/atomata_hubs.png",
    4,1.5,-5.9,20,.75,.37,12,7],
    ["/images/atomata_mobile.png",
    4,-1.5,-8.9,25,1,.45,12,7],
    ["/images/drjart_diagram.png",
    5,-.5,-10,40,0,.55,12,7.38],
    ["/images/biochem.jpg", 
    7, .9,-13.5, 65,0,.4,8.87,7]
]
