import React from "react";
import { animated } from "react-spring";
import styled from "styled-components";

import Main from "./imageTest";

const ActiveContentWrapper = styled(animated.div)`
  flex-shrink: 0;
  background: white;
  position: relative;
  overflow: hidden;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <h1 style={{ color: "red" }}>
          Something went wrong.
          <br /> {JSON.stringify(this.state.error)}{" "}
        </h1>
      );
    }

    return this.props.children;
  }
}

const ActiveContent = (props) => {
  return (
    <ActiveContentWrapper style={props.style}>
      {props.showCube && (
        <ErrorBoundary>
          <Main onScroll={props.onScroll} />
        </ErrorBoundary>
      )}
    </ActiveContentWrapper>
  );
};

export default ActiveContent;
