import React, { useRef, useState, useEffect } from 'react';
import { animated, useSpring, config, useChain, } from 'react-spring';

import NavLeft from '../components/navLeft';
import ActiveHeadline from '../components/activeHeadline';
import RightContentBar from '../components/rightContentBar';
import ActiveContent from '../components/activeContent';

import useWindowDimensions from '../utils/useWindowDimensions'

import { BaseContainerWrapper, BodyContainer, ContentContainer } from './baseContainer__styles'

const AnimatedActiveHeadline = animated(ActiveHeadline)
const AnimatedActiveContent = animated(ActiveContent)

const BaseContainer = (props) => {

	const [showActiveHeader, setShowActiveHeader] = useState(false);
	const [showCube, setShowCube] = useState(false);
	const { width } = useWindowDimensions();

	const activeContentSpringRef = useRef();
	const [activeContentSpring, setActiveContentSpring] = useSpring(() => {
		return {
			width: '0%',
			borderRight: 'solid transparent 1px',
			backgroundColor: 'white',
			config: config.slow,
			ref: activeContentSpringRef,
		}
	});

	useEffect(() => {
		setActiveContentSpring(() => {
			return {
				width: props.loadingComplete ? (width > 500 ? '70%' : '77%') : '0%',
				borderRight: props.loadingComplete ? 'solid black 1px ' : 'solid transparent 1px',
				backgroundColor: props.loadingComplete ? 'black' : 'white',
				config: config.molasses,
				ref: activeContentSpringRef,
				onRest: () => props.loadingComplete && setShowCube(true),
			}
		});
	}, [props, setShowCube, activeContentSpringRef, setActiveContentSpring, width]);

	useChain([activeContentSpringRef], [0]);

	const bodyHeightSpring = useSpring({
		height: '100%',
		from: { height: '0%' },
		onRest: () => setShowActiveHeader(true),
		config: config.slow
	});

	return (
		<BaseContainerWrapper>
			<NavLeft leftOffset={'5%'} style={bodyHeightSpring} 
			loadingComplete={props.loadingComplete} />

			<BodyContainer >
				<AnimatedActiveHeadline
					show={showActiveHeader}
					showPlaceHolder={props.isLoading}
					loadingComplete={props.loadingComplete}
					fontColorSpring={props.fontColorSpring}
				/>

				<ContentContainer>
					<AnimatedActiveContent
						style={activeContentSpring}
						showCube={showCube}
						onScroll={props.onScroll}
						top={props.top}
					/>
					<RightContentBar
						loadingComplete={props.loadingComplete}
						acceptContentBarActions={showCube}
					/>

				</ContentContainer>
			</BodyContainer>
		</BaseContainerWrapper>
	);
};


export default BaseContainer;
