import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { animated, config, useTrail } from "react-spring";
import ContentOverviewItem from "./contentOverviewItem";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";

import { FaLink } from 'react-icons/fa'

const NavigationContainer = styled(animated.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (max-width: 500px) {
    //width: 70px;
    //flex-shrink: 0;
  }
`;


const RightContentBar = (props) => {
  const [containers] = useState([
    {
      name: "ABOUT",
      image: "/images/about.png",
      squareImage: "/images/atomata__square.jpg",
      exiting: false,
      entering: false,
      heightProgress: 0,
      dateRange: <div>CURRENT</div>,
      titleName: <div>human being</div>,
      companyName: <div>about me</div>,
    },
    {
      name: "PAGER",
      image: "/images/pager_square.png",
      exiting: false,
      entering: false,
      heightProgress: 0,
      dateRange: <div>2023 : CURRENT</div>,
      titleName: <div>senior software engineer</div>,
      companyName: (
        <div>
          <a
            href="https://pager.xyz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            pager
            <FaLink />
          </a>
        </div>
      ),
    },
    {
      name: "SATURN",
      image: "/images/saturn_square.png",
      squareImage: "/images/saturn_square.webp",
      exiting: false,
      entering: false,
      heightProgress: 0,
      dateRange: <div>2020 : 2023</div>,
      titleName: <div>senior software engineer</div>,
      companyName: (
        <div>
          <a
            href="https://joinsaturn.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            saturn
            <FaLink />
          </a>
        </div>
      ),
    },
    {
      name: "MAUDE",
      image: "/images/maude.png",
      squareImage: "/images/maude__square.jpg",
      exiting: false,
      entering: false,
      heightProgress: 0,
      dateRange: <div>2019 : 2020</div>,
      titleName: <div>freelance web engineer</div>,
      credits: (
        <div>
          Lead Designer :
          <a
            href="https://studiopaquette.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Maude PB
          </a>
        </div>
      ),
      companyName: (
        <div>
          <a
            href="https://getmaude.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            maude
            <FaLink />
          </a>
        </div>
      ),
      iconDescriptions: [
        "Built off Shopify",
        "Heavy Javascript for interactivity",
        "Custom HTML liquid templates on all pages",
        "Custom styles",
        "Data aggregation pipeline on AWS",
        "Data aggregated on PostgreSQL",
        "Worked within a team of 4",
      ],
    },

    {
      name: "ATOMATA",
      image: "/images/atomata.jpg",
      squareImage: "/images/atomata__square.jpg",
      exiting: false,
      entering: false,
      heightProgress: 0,
      dateRange: <div>2017 : 2019</div>,
      titleName: <div>co-founder + tech lead</div>,
      credits: (
        <div>
          Lead Designer :
          <a
            href="https://drfarrell.github.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Daniel Farrell
          </a>
        </div>
      ),
      companyName: <div>atomata</div>,
    },
    {
      image: "/images/drjart.jpg",
      squareImage: "/images/drjart__square.jpg",
      exiting: false,
      entering: false,
      heightProgress: 0,
      dateRange: <div>2019</div>,
      titleName: <div>freelance data engineer</div>,
      companyName: (
        <div>
          <a
            href="https://us.drjart.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            dr. jart
            <FaLink />
          </a>
        </div>
      ),
    },
    {
      name: "ARROW",
      image: "/images/arrow.jpg",
      squareImage: "/images/atomata__square.jpg",
      exiting: false,
      entering: false,
      heightProgress: 0,
      dateRange: <div>2018</div>,
      titleName: <div>Data science intern</div>,
      companyName: (
        <div>
          <a
            href="https://www.arrow.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            arrow electronics
            <FaLink />
          </a>
        </div>
      ),
    },
    {
      name: "DU",
      image: "/images/du.jpg",
      squareImage: "/images/atomata__square.jpg",
      exiting: false,
      entering: false,
      heightProgress: 0,
      dateRange: <div>2017 : 2018</div>,
      titleName: <div>computational biochemist</div>,
      companyName: <div>university of denver</div>,
    },
    {
      hide: true,
      name: "",
      image: "",
      squareImage: "",
      exiting: false,
      entering: false,
      heightProgress: 0,
      dateRange: "",
      titleName: <div>miss you already!</div>,
      companyName: <div>Talk soon! 👋</div>,
      icons: <></>,
    },
  ]);

  const [contentBarRef, { height: contentBarHeight, width: contentBarWidth }] =
    useMeasure({ debounce: 1000, polyfill: ResizeObserver });
  const [openContnent, setOpenContent] = useState(-1);
  const [hideTopBorder, setHideTopBorder] = useState(false);
  const { top, acceptContentBarActions } = props;

  const [imageOpacityTrail, setImageOpacity] = useTrail(
    containers.length,
    () => ({
      from: { opacity: 0 },
    })
  );

  useEffect(() => {
    acceptContentBarActions && setImageOpacity({ opacity: 1 });
    acceptContentBarActions && setHideTopBorder(true);
  }, [acceptContentBarActions, setImageOpacity, setHideTopBorder]);

  const [containerTransition] = useTrail(8, () => ({
    from: { width: "0%" },
    width: "100%",
    config: config.molasses,
    //onRest: () => setAcceptContentBarActions(true)
  }));

  /* 	useEffect(() => {
			const scrollRatio = props.top/contentBarHeight
			scrollRatio % 1 > 0.6 ? setOpenContent(Math.round(scrollRatio)) : setOpenContent(-1)
			console.log(scrollRatio, contentBarHeight, props.top)
		}, [props, setOpenContent, contentBarHeight]) */

  return (
    <NavigationContainer ref={contentBarRef}>
      {containers.map((child, index) => (
        <ContentOverviewItem
          key={`CONTENT_ITEM_#${index}`}
          child={child}
          elementSpring={containerTransition[index]}
          index={index}
          barHeight={contentBarHeight}
          barWidth={contentBarWidth}
          imageOpacitySpring={imageOpacityTrail[index]}
          showItem={acceptContentBarActions}
          hideTopBorder={hideTopBorder}
        />
      ))}
    </NavigationContainer>
  );
};

export default RightContentBar;
