import React, { useEffect, useState, useCallback } from "react";
import { useSpring } from "react-spring";
import { useSpring as useSpringThree } from "react-spring/three";

export default function useScrollContextManager({
  appHeight,
  maxContentSlots,
}) {
  /*
	
    This is a custom hook that manages dispatching opening & closing 
    of content blocks via internal state that can be passed the the scrollStateContext provider.

    It contains the core 'top' springs for DOM & Three.js objects, which are used throughout the app for 
    content movement & color change orchestration.

    @param {number} appHeight - the height off to screen in px.
    @param {number} maxContentSlots - the maximum number of content items to accommodate in the scroll.
	
	@returns {object} stateForScrollContext - object to pass to scrollStateContext provider. Contains top springs, appHeight, & onScroll callback fn.
	@returns {number} openContent - number representing the content to open, -1 when all content is closed.
	@returns {object} backgroundColorSpring - Spring to manage & co-ordinate color transition in response to scroll state.

    */

  const [openContent, setOpenContent] = useState(-1);
  const [stateForScrollContext, setStateForScrollContext] = useState({});

  // callback to run on intermediate frames of the scroll spring (top) below
  const updateOpenContent = useCallback(
    ({ top }) => {
      // Each content section is open for ~2.5 x screen height, to allow users to scroll through proportionally
      // to their device height
      const scrollRatio = top / (appHeight * 2.5);

      // Provide 'spacing' UI when openContent === -1 (base case) that shows all content for 0.2 x screen height
      // This is shown between content sections
      scrollRatio % 1 > 0.2
        ? setOpenContent(Math.min(Math.ceil(scrollRatio) - 1, 8))
        : setOpenContent(-1);
    },
    [appHeight, setOpenContent]
  );

  // Need to maintain distinct internal spring objects for dom elements (useSpring) & Three.js elements (useSpringThree)
  // Their implementations are optimized for each settings so the duplication is superior here.
  const [{ top: topThree }, setThree] = useSpringThree(() => ({ top: 0 }));
  const [{ top }, set] = useSpring(() => ({
    top: 0,
    onFrame: updateOpenContent,
  }));

  // update the scroll springs onScroll. This function is called by the activeBody component through the context provider.
  const onScroll = useCallback(
    (e) => {
      // stops users from overScrolling past last content elements, but lets them scroll back up.
      const scrollIsValid = (e) =>
        openContent !== maxContentSlots || e.target.scrollTop * 2 < top.value;

      if (scrollIsValid(e)) {
        set({ top: e.target.scrollTop * 2, onFrame: updateOpenContent });
        setThree({ top: e.target.scrollTop * 2 });
      }
    },
    [set, setThree, openContent, top, maxContentSlots, updateOpenContent]
  );

  useEffect(() => {
    setStateForScrollContext({ appHeight, onScroll, top, topThree });
  }, [appHeight, onScroll, top, topThree, setStateForScrollContext]);

  console.log({ top, topThree });

  const backgroundColorSpring = top.interpolate({
    range: [
      0,
      appHeight * 2.5,
      appHeight * 5,
      appHeight * 7.5,
      appHeight * 10,
      appHeight * 12.5,
      appHeight * 15,
      appHeight * 17.5,
      appHeight * 20,
    ],
    output: [
      "white",
      "#E8AFC4",
      "#B3E2F5",
      "#DEA1FF",
      "#F6E3CB",
      "#28C9A1",
      "#7FC0EF",
      "#E8EEF2",
      "#F4F1DE",
    ],
  });

  return { stateForScrollContext, openContent, backgroundColorSpring };
}
