import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { animated, useSpring, config, to } from 'react-spring';
import useWindowDimensions from '../utils/useWindowDimensions'


const fadeOutSeconds = 1;

const Nav = styled(animated.nav)`
    height: 300px;
    border-bottom: black 1px solid;
    display: flex;
    flex-shrink: 0;
    width: 0%;
    position: relative;
    overflow: hidden;
`

const logoStyling = (props) => ({
    marginTop: 'auto',
    height: '68px',
    marginLeft: 'calc(5% + 16px)',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '56px',
    width: '100%',
    transition: `opacity 0.6s ease`,
    position: 'absolute',
    top: '68px',
    bottom: '0',
});


const LoadingDots = styled.h2`
    margin-top: auto;
    height: 68px;
    margin-left: calc(5% + 16px);
    font-family: Montserrat, sans-serif;
    font-size: 56px;
    width: 100%;
    position: absolute;
    top: 68px;
    bottom: 0;
    margin-bottom: 0;

    opacity: ${props => props.isLoading ? 0 : 1}
    transition: opacity 0.3s ease-in 1s;

    @media screen and (max-width: 500px) {
        margin-left: 13%;
        height: 58px;
        font-size: 46px;
    }

`;

const Logo = styled.h2(props => ({
    ...logoStyling(props),
    visibility: props.isLoading ? 'hidden' : 'visible',
    opacity: props.isLoading ? 0 : 1,
    marginBottom: 0,
}));



const NavTop = (props) => {

    const [logoString, setLogoString] = useState('A.A.');
    const [loadingSequenceComplete, setLoadingSequenceComplete] = useState(false);
    const endLogoString = 'A.A.';
    const typingIntervalMillis = 300;
    const {width} = useWindowDimensions()

    const incrementLoadingString =
          logoString => (logoString === '....' ? '' : logoString + '.');
    const incrementInitialString =
          logoString => (logoString === '....' ? '' : logoString + endLogoString[logoString.length]);

    useEffect(() => {
        if (logoString !== endLogoString){
            const id = setInterval(() => {
                setLogoString(logoString => {
                    return props.isLoading || logoString !== '' && !logoString.includes('A') ?
                        incrementLoadingString(logoString):
                        incrementInitialString(logoString);
                });
            }, typingIntervalMillis);
            return () => clearInterval(id);
        }
    }, [logoString, props.isLoading]);

    useEffect(() => {
        if(logoString === endLogoString && !props.isLoading){
            setTimeout(() => setLoadingSequenceComplete(true), typingIntervalMillis);
        }
    }, [logoString, setLoadingSequenceComplete, props]);

    useEffect(() => props.setLoadingTransitionComplete(loadingSequenceComplete),
              [loadingSequenceComplete, props.setLoadingTransitionComplete])

    const navTopSpring = useSpring({
        maxHeight: loadingSequenceComplete ? (width > 500 ? '81px' : '60px') : '300px', 
        width: '100%', 
        from:{width: '0%'}, 
        config: config.molasses,
    })

    return (
        <Nav style={navTopSpring}>
          <LoadingDots  {...props} >
            {props.showText && logoString }
          </LoadingDots>
        </Nav>
    );
};

export default NavTop;
