import React, { useEffect, useState, useContext } from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { OpenContext } from "../context/scrollStateContext";
import useMeasure from "react-use-measure";
import { isMobile } from "react-device-detect";
import useWindowDimensions from "../utils/useWindowDimensions";
import { ResizeObserver } from "@juggle/resize-observer";

const PortfolioElement = styled(animated.div)`
  border-top: solid black 1px;
  width: 0%;
  height: 74px;
  flex-shrink: 0;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  justify-content: start;

  &:nth-child(1) {
    border-top: 1px solid black !important;
  }
`;

const PortfolioElementImage = styled(animated.img)`
  width: 100%;
  object-fit: cover;
  height: 100%;
  will-change: width, opacity;

  display: ${(props) => (props.hide ? "none" : "inline-block")};
`;

const ImageWrapper = styled(animated.div)`
  padding: 8px;
  height: 100%;
  opacity: 0;
  min-height: 57px;
  z-index: 1;
  @media screen and (max-width: 500px) {
    height: 40vh;
    flex-shrink: 1;
  }
`;

const TextWrapper = styled(animated.div)`
  margin-top: auto;
  padding: 8px;
  transform-origin: center left;
  position: absolute;
  bottom: 72px;
  cursor: default;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    top: 45%;
  }
`;
const IconWrapper = styled(animated.div)`
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 500px) {
    display: none;
  }

  svg {
    color: black;
    height: auto;
    width: 20px;
    margin-right: 3px;
  }
  span {
    font-family: Inconsolata, monospace;
    margin-bottom: 6px;
    margin-left: -3px;
    height: 100%;
    font-size: 10px;
  }
`;
const DateRange = styled(animated.h5)`
  font-family: Inconsolata, monospace;
  font-weight: normal;
  margin: 0;
  font-size: 14px;

  @media screen and (max-width: 500px) {
    writing-mode: vertical-lr;
    flex-basis: 10px;
    transform: rotate(180deg);
    font-family: montserrat;
    font-size: 11px;
    width: 10px;
  }

  div {
    position: relative;
    transition: color 0.2s ease-in, background-color 0.2s ease-in,
      opacity 0.3s ease-in 0.5s;

    &::before {
      content: "";
      position: absolute;
      left: -8px;
      right: -8px;
      top: 0;
      bottom: 0;
      z-index: -1;
      background-color: transparent;
      color: black;
      transition: inherit;
    }

    &:hover {
      color: white;
      a {
        color: #e95858;
      }

      &::before {
        background-color: black;
      }
    }
  }
`;
const TitleName = styled(animated.h3)`
  margin-top: 2px;
  margin-bottom: 0;
  font-family: Inconsolata, monospace;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;

  @media screen and (max-width: 500px) {
    writing-mode: vertical-rl;
    margin: 0;
    height: -100%;
    transform: rotate(180deg);
    font-size: 14px;
    font-family: montserrat;
    width: 16px;
  }

  a {
    color: black;
    text-decoration: none;
    margin-left: 6px;
  }

  div {
    position: relative;
    transition: color 0.2s ease-in, background-color 0.2s ease-in,
      opacity 0.3s ease-in 0.5s;

    &::before {
      content: "";
      position: absolute;
      left: -8px;
      right: -8px;
      top: 0;
      bottom: 0;
      z-index: -1;
      background-color: transparent;
      color: black;
      transition: inherit;
    }

    &:hover {
      color: white;
      a {
        color: #e95858;
      }

      &::before {
        background-color: black;
      }
    }
  }
`;

const ContentButton = styled(TitleName)`
  color: black;
  transition: color 0.2s ease-in, background-color 0.2s ease-in,
    opacity 0.3s ease-in 0.5s;
  background-color: transparent;

  a {
    padding-top: 7px;
    color: black;
    transition: all 0.2s ease-in;
    transition: inherit;
  }

  div {
    position: relative;
    transition: color 0.2s ease-in, background-color 0.2s ease-in,
      opacity 0.3s ease-in 0.5s;

    &::before {
      content: "";
      position: absolute;
      left: -8px;
      right: -8px;
      top: 0;
      bottom: 0;
      z-index: -1;
      background-color: transparent;
      color: black;
      transition: inherit;
    }

    &:hover {
      color: white;
      a {
        color: #e95858;
      }

      &::before {
        background-color: black;
      }
    }
  }
`;

const CompanyName = styled(animated.h1)`
  margin: 0;
  text-transform: uppercase;
  font-family: montserrat;
  font-weight: bold;
  font-size: 42px;
  line-height: 1;

  @media screen and (max-width: 500px) {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 24px;
    width: 22px a {
      align-items: end;
    }

    svg {
      height: 18px !important;
      margin-top: 5px;
      margin-left: 0;
    }
  }

  a {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    svg {
      color: inherit;
      margin-left: 3px;
      height: 34px;
    }
  }

  div {
    position: relative;
    transition: color 0.2s ease-in, background-color 0.2s ease-in,
      opacity 0.3s ease-in 0.5s;

    &::before {
      content: "";
      position: absolute;
      left: -8px;
      right: -6px;
      top: 0;
      bottom: 0;
      z-index: -1;
      background-color: transparent;
      color: black;
      transition: inherit;
    }

    &:hover {
      color: white;
      a {
        color: white;
      }

      &::before {
        background-color: black;
      }
      svg {
        color: #e95858;
      }
    }
  }
`;

const ContentOverviewItem = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [animateInDetails, setAnimateInDetails] = useState(false);
  const openContent = useContext(OpenContext);
  const [textRef, { height: textHeight }] = useMeasure({
    polyfill: ResizeObserver,
  });
  const { width } = useWindowDimensions();

  const [elementSpring, setElementSpring] = useSpring(() => ({
    from: { height: "74px", opacity: 1 },
  }));

  const [{ height: imgWrapperHeight }, setImgWrapperSpring] = useSpring(() => ({
    from: { height: "57px" },
  }));

  const [clientDetailSpring, setClientDetailSpring] = useSpring(() => ({
    from: { transform: "translateY(-30px)", opacity: 0 },
  }));

  useEffect(() => {
    animateInDetails &&
      setClientDetailSpring({
        transform: "translateY(0px)",
        opacity: 1,
        display: isMobile ? "flex" : "block",
        onFrame: () => {},
        onRest: () => {},
      });
  }, [setClientDetailSpring, animateInDetails]);

  useEffect(() => {
    if (openContent === props.index) {
      //console.log('EQUAL!', props.barHeight, props.index);
      setElementSpring({
        height: isMobile ? props.barHeight * 0.5 + "px" : props.barWidth + "px",
        marginTop: props.index * -1,
        onFrame: (val) => {
          const detailsReadyToAnimate =
            val.height.split("p")[0] >= props.barWidth * 0.8;
          if (detailsReadyToAnimate) {
            setAnimateInDetails(true);
          }
        },
      });
      //setImgWrapperSpring({
      //	height: width > 500 ? props.barWidth + 'px' : (props.barHeight - textHeight) + 'px'
      //});
      //console.log((props.barHeight - textHeight) + 'px', props.barHeight, textHeight)
    } else if (openContent !== props.index && openContent !== -1) {
      setElementSpring({
        height: "0px",
        marginTop: 0,
        opacity: 0,
        onFrame: (frame) => {
          return;
        },
      });
    } else {
      //console.log("BASE CASE?", openContent, props.index)

      //setImgWrapperSpring({ height: '57px' });
      setElementSpring({
        height: "74px",
        opacity: 1,
        onFrame: (frame) => {
          return;
        },
      });
      setClientDetailSpring({
        transform: "translateY(-30px)",
        opacity: 0,
        onFrame: (frame) => {
          if (frame.opacity <= 0.1) {
          }
        },
        onRest: () => !isMobile && setClientDetailSpring({ display: "none" }),
      });
    }
    setAnimateInDetails(false);
  }, [
    props,
    setElementSpring,
    setImgWrapperSpring,
    setExpanded,
    expanded,
    setClientDetailSpring,
    openContent,
    textHeight,
    width,
  ]);

  return (
    <PortfolioElement style={{ ...props.elementSpring, ...elementSpring }}>
      <ImageWrapper
        style={{ height: "100%", ...props.imageOpacitySpring }}
        {...imgWrapperHeight}
      >
        <PortfolioElementImage
          src={props.child.image}
          hide={props.child.hide}
        />
      </ImageWrapper>
      {props.showItem && (
        <>
          <TextWrapper style={{ ...clientDetailSpring }} ref={textRef}>
            {/*<IconWrapper>
							{props.child.icons}
						</IconWrapper>*/}
            <DateRange>{props.child.dateRange}</DateRange>
            <TitleName>{props.child.titleName}</TitleName>
            {props.child.credits && !isMobile && (
              <ContentButton>{props.child.credits}</ContentButton>
            )}
            <CompanyName>{props.child.companyName}</CompanyName>
          </TextWrapper>
        </>
      )}
    </PortfolioElement>
  );
};

export default ContentOverviewItem;
