import * as THREE from "three/src/Three";
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  Suspense,
  useContext,
} from "react";
// A THREE.js React renderer, see: https://github.com/drcmda/react-three-fiber
import { Canvas, useRender, useThree, useFrame } from "react-three-fiber";
// A React animation lib, see: https://github.com/react-spring/react-spring
import {
  useSpring,
  a,
  animated,
  interpolate,
  config,
} from "react-spring/three";
import {
  animated as animatedDom,
  useSpring as useSpringDom,
  interpolate as interpolateDom,
} from "react-spring";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import data from "../data";
import Thing from "./threeTestCanvas";

import polyfill from "@juggle/resize-observer";
import { ScrollContext, OpenContext } from "../context/scrollStateContext";

function Text({
  children,
  position,
  opacity,
  color,
  appHeight,
  fontSize = 410,
}) {
  const {
    size: { width, height },
    viewport: { width: viewportWidth, height: viewportHeight },
  } = useThree();
  const scale = viewportWidth > viewportHeight ? viewportWidth : viewportHeight;
  const canvas = useMemo(() => {
    const canvas = document.createElement("canvas");
    canvas.width = canvas.height = 2048;
    const context = canvas.getContext("2d");
    context.font = `bold ${fontSize}px -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif`;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillStyle = color;
    context.fillText(children, 1024, 1024 - 410 / 2);
    return canvas;
  }, [children, color, fontSize]);
  return (
    <a.sprite scale={[scale, scale, 1]} position={position}>
      <a.spriteMaterial attach="material" transparent opacity={opacity}>
        <canvasTexture
          attach="map"
          image={canvas}
          premultiplyAlpha
          onUpdate={(s) => (s.needsUpdate = true)}
        />
      </a.spriteMaterial>
    </a.sprite>
  );
}

/** This component loads an image and projects it onto a plane */
const Image = React.memo(
  ({
    url,
    scale,
    contentGroup,
    top,
    mouse,
    scrollMax,
    appHeight,
    texture,
    ...props
  }) => {
    //const texture = useMemo(() => new THREE.TextureLoader().load(url), [url])
    const [hovered, setHover] = useState(false);
    const [inView, setInView] = useState(false);
    const hover = useCallback(() => setHover(true), []);
    const unhover = useCallback(() => setHover(false), []);
    const { factor } = useSpring({ factor: hovered ? 1.3 : 1 });
    const [frustum, cameraViewProjectionMatrix] = useMemo(
      () => [new THREE.Frustum(), new THREE.Matrix4()],
      []
    );
    const ref = useRef();
    const { camera } = useThree();
    const [{ opacity }, setOpacity] = useSpring(() => ({
      opacity: 0,
      config: config.slow,
    }));

    // get & store the top value when the component is rendered,
    // this value will seve as a static offset to the 'global' top derived from scroll.
    const [triggerOpenAt] = useState(top.getValue() + 100);

    useFrame(() => {
      camera.updateMatrixWorld(); // make sure the camera matrix is updated
      camera.matrixWorldInverse.getInverse(camera.matrixWorld);
      cameraViewProjectionMatrix.multiplyMatrices(
        camera.projectionMatrix,
        camera.matrixWorldInverse
      );
      frustum.setFromMatrix(cameraViewProjectionMatrix);

      ref.current && frustum.intersectsObject(ref.current)
        ? setInView(true)
        : setInView(false);
    });

    useEffect(() => {
      setOpacity(() => ({ opacity: inView ? 1 : 0 }));
    }, [inView, setOpacity]);

    //console.log('RENDERING IMAGE', url, contentGroup, triggerOpenAt, triggerOpenAt / appHeight, props.testPosition)
    //console.log(contentGroup)

    return (
      <a.mesh
        ref={ref}
        receiveShadow
        castShadow
        position={props.testPosition.interpolate(([x, y, z]) => {
          //console.log(`BATCH#${contentGroup}:${url}`, x, y, y - ((triggerOpenAt * props.factor) / scrollMax), z)
          return [
            x,
            y - (triggerOpenAt * props.factor) / scrollMax,
            z - triggerOpenAt / 6000,
          ];
        })}
        {...props}
        transparent={true}
        scale={factor.interpolate((f) => [
          scale * f * props.widthScale,
          scale * f * props.heightScale,
          1,
        ])}
      >
        <planeBufferGeometry attach="geometry" args={[1, 1]} />
        <a.meshLambertMaterial
          attach="material"
          transparent={true}
          opacity={opacity ? opacity : 1}
        >
          <primitive attach="map" object={texture} />
        </a.meshLambertMaterial>
      </a.mesh>
    );
  }
);

/** This component creates a bunch of parallaxed images */
export function Images({ top, mouse, openContentIndex, appHeight }) {
  function imageTextureObjStore(imageTextureObj, newGroups) {
    const loader = new THREE.TextureLoader();
    const newTextures = data
      .filter(([_, group]) => newGroups.includes(group))
      .reduce((obj, [url]) => {
        obj[url] = loader.load(url);
        return obj;
      }, {});

    return {
      ...newTextures,
      ...imageTextureObj,
    };
  }

  //const imageTextures__ = useLoader(THREE.TextureLoader, data.map(val => val[0]))
  //console.log(imageTextures__)

  const { size } = useThree();
  const scrollMax = size.height * 4.5;

  const [contentIndexesEncountered, setContentIndexesEncountered] = useState(
    []
  );

  // object containing mappings of URL names to THREE.js textures.
  const [imageTextures, setImageTextures] = useState({});
  const [images, setImages] = useState([]);

  //openContentIndex !== -1 && openContentIndex < data.length ? data[openContentIndex] : []

  // update active Images to include all content indexes encountered
  useEffect(() => {
    setImages((images) => [
      ...images,
      ...data.filter(
        (entry) =>
          entry[1] ===
          contentIndexesEncountered[contentIndexesEncountered.length - 1]
      ),
    ]);
    setImageTextures((imageTextures) =>
      imageTextureObjStore(imageTextures, [
        contentIndexesEncountered[contentIndexesEncountered.length - 1] + 1,
      ])
    );
  }, [contentIndexesEncountered]);

  useEffect(() => {
    setContentIndexesEncountered((indexes) =>
      indexes.includes(openContentIndex)
        ? indexes
        : [...indexes, openContentIndex]
    );
  }, [openContentIndex]);

  return images.map(
    (
      [url, contentGroup, x, y, factor, z, scale, widthScale, heightScale],
      key
    ) => (
      <Image
        key={`ContentImage#${key}`}
        contentGroup={contentGroup}
        url={url}
        cords={[x, y, z]}
        scale={scale}
        scrollMax={scrollMax}
        top={top}
        widthScale={widthScale}
        heightScale={heightScale}
        appHeight={appHeight}
        testPosition={interpolate([top, mouse], (top, mouse) => [
          (-mouse[0] * factor) / 50000 + x,
          (mouse[1] * factor) / 50000 + y + (top * factor) / scrollMax,
          z + top / 6000,
        ])}
        factor={factor}
        texture={imageTextures[url]}
      ></Image>
    )
  );
}

/** This component rotates a bunch of stars */
const Stars = ({ position, color, appHeight, theta, ...props }) => {
  let group1 = useRef();
  //group2 = useRef(),
  //group3 = useRef()

  //let theta = 90,
  //   theta1 = 89.99,
  //   theta2 = 89.98

  useRender(() => {
    theta += 0.01;
    //theta1 += 0.01
    //theta2 += 0.01

    const r = 3 * Math.sin(THREE.Math.degToRad(theta));
    const s = Math.cos(THREE.Math.degToRad(theta));
    //      s2 = Math.cos(THREE.Math.degToRad(theta1)),
    //      s3 = Math.cos(THREE.Math.degToRad(theta2))
    group1.current.rotation.set(r, r, r);
    //group2.current.rotation.set(r, r, r)
    //group3.current.rotation.set(r, r, r)
    group1.current.scale.set(s, s, s);
    //group2.current.scale.set(s2, s2, s2)
    //group3.current.scale.set(s3, s3, s3)
  });
  const [geo, coords] = useMemo(() => {
    const geo = new THREE.BoxBufferGeometry(2, 2, 2);
    const coords = new Array(500)
      .fill()
      .map((v, i) => [
        [
          Math.random() * 1500 - 750,
          Math.random() * 1500 - 750,
          Math.random() * 1500 - 750,
        ],
        i,
      ]);
    return [geo, coords];
  }, []);

  const starColor = useMemo(() => {
    return props.testColor
      ? props.testColor
      : color.interpolate({
          range: [
            0,
            appHeight * 2.5,
            appHeight * 5,
            appHeight * 7.5,
            appHeight * 10,
            appHeight * 12.5,
            appHeight * 15,
            appHeight * 17.5,
            appHeight * 20,
          ],
          output: [
            "white",
            "#E8AFC4",
            "#B3E2F5",
            "#DEA1FF",
            "#F6E3CB",
            "#28C9A1",
            "#7FC0EF",
            "#E8EEF2",
            "#F4F1DE",
          ],
        });
  }, [color, appHeight, props]);

  return (
    <>
      <a.group ref={group1} position={position}>
        {coords.map(([[p1, p2, p3], _], i) => (
          <mesh key={i} geometry={geo} position={[p1, p2, p3]}>
            <a.meshBasicMaterial
              transparent
              attach="material"
              color={starColor}
            />
          </mesh>
        ))}
      </a.group>
    </>
  );
};

const WordBoxes = React.memo(({ cubeWord, position, appHeight }) => {
  return cubeWord
    .split("")
    .reverse()
    .map((val, index) => (
      <Suspense fallback={null}>
        <Thing
          path1={"/images/question__square.png"}
          path2={"/images/maude__square.jpg"}
          verticalOffset={index * 1.5 - 3}
          character={val}
          index={index}
          key={`WORD_BOX#${index}`}
          position={position}
        />
      </Suspense>
    ));
});

const SpotlightMemo = React.memo(a.spotLight);
const AnimatedText = animated(Text);

/** This component maintains the scene */
export const Scene = React.memo(
  ({ top, mouse, appHeight, openContentIndex }) => {
    const [cubeWord, setCubeWord] = useState(isMobile ? "↑SWIPE↑" : "SCROLL↓");

    return (
      <>
        <SpotlightMemo
          intensity={1.1}
          color="white"
          position={mouse.interpolate((x, y) => [x / 100, -y / 100, 6.5])}
        />
        <Stars
          position={top.interpolate((top) => [0, -2 + top / 20, 0])}
          color={top}
          appHeight={appHeight}
          theta={90}
        />
        <Stars
          position={top.interpolate((top) => [0, -120 + top / 20, 0])}
          color={top}
          appHeight={appHeight}
          theta={91}
        />
        <Stars
          position={top.interpolate((top) => [0, -400 + top / 20, 0])}
          color={top}
          appHeight={appHeight}
          theta={100}
        />
        <WordBoxes cubeWord={cubeWord} position={top} appHeight={appHeight} />
      </>
    );
  }
);

const TextWrapper = styled(animatedDom.div)`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 5%;
  max-width: 750px;
  pointer-events: none;
`;

const HeaderText = styled(animatedDom.span)`
  font-size: 38px;
  font-family: Inconsolata, monospace;
  @media screen and (max-width: 500px) {
    font-size: 28px;
  }
`;

const BodyText = styled(animatedDom.span)`
  font-size: 26px;
  font-family: Inconsolata, monospace;

  b {
    text-decoration: underline;
    filter: hue-rotate(200deg);
  }

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

const BodyTextTop = styled(BodyText)`
  -webkit-text-stroke: 0.5px black;
  color: transparent !important;

  @media screen and (max-width: 500px) {
    -webkit-text-stroke: 0.15px black;
  }
`;

/** Main component */
export default function Main() {
  // This tiny spring right here controlls all(!) the animations, one for scroll, the other for mouse movement ...
  const [{ mouse }, set] = useSpring(() => ({ mouse: [0, 0] }));
  const [{ coords }, setMouseDom] = useSpringDom(() => ({ coords: [0, 0] }));
  const onMouseMove = useCallback(
    ({ clientX: x, clientY: y }) => {
      set({ mouse: [x - window.innerWidth / 2, y - window.innerHeight / 2] });
      setMouseDom({
        coords: [x - window.innerWidth / 2, y - window.innerHeight / 2],
      });
    },
    [set, setMouseDom]
  );
  const {
    onScroll,
    topThree: top,
    top: topDom,
    appHeight,
  } = useContext(ScrollContext);
  const openContentIndex = useContext(OpenContext);

  const textAnimationStyle = useMemo(
    () => ({
      color: topDom.interpolate({
        range: [
          0,
          appHeight * 2.5,
          appHeight * 5,
          appHeight * 7.5,
          appHeight * 10,
          appHeight * 12.5,
          appHeight * 15,
          appHeight * 17.5,
          appHeight * 20,
        ],
        output: [
          "white",
          "#E8AFC4",
          "#B3E2F5",
          "#DEA1FF",
          "#F6E3CB",
          "#28C9A1",
          "#7FC0EF",
          "#E8EEF2",
          "#F4F1DE",
        ],
      }),
    }),
    [topDom, appHeight]
  );

  return (
    <>
      <TextWrapper
        style={{
          top: topDom.interpolate((top) => 14 * appHeight - top),
          transform: interpolateDom(
            [coords],
            ([x, y]) =>
              `translateX(${0 - x / 70}px) translateY(${0 - y / 70}px)`
          ),
          marginLeft: "7%",
        }}
      >
        <BodyText style={textAnimationStyle}>
          I Developed an email-based data intake pipeline that parsed & stored
          muti-vendor point of sale spreadsheet data into a central sales DB
          schema, utilizing python + cloud tooling on AWS.
          <br />
          <br />
          Data is stored on an RDS PostgreSQL instance & can be queried directly
          by financial reporting analysts.
          <br />
          An 8-page tableau dashboard was also integrated & developed to allow
          efficient reporting of sales data to the executive team.
        </BodyText>
      </TextWrapper>

      <TextWrapper
        style={{
          top: topDom.interpolate((top) => 16.5 * appHeight - top),
          transform: interpolateDom(
            [coords],
            ([x, y]) =>
              `translateX(${0 - x / 70}px) translateY(${0 - y / 70}px)`
          ),
          marginLeft: "7%",
        }}
      >
        <BodyText style={textAnimationStyle}>
          At Arrow, I helped identify systematic errors impacting data integrity
          across Arrow’s largest components warehouses, affecting millions of
          dollars of inventory & compliance in external audits.
          <br />
          <br />I regularly utilized R, SQL, & the tidyverse for daily analysis,
          visualizations, and reporting to management. Employed techniques
          including multilevel linear models, logistic regression models, ARIMA,
          & Bayesian state time series models.
        </BodyText>
      </TextWrapper>

      <TextWrapper
        style={{
          top: topDom.interpolate((top) => 19 * appHeight - top),
          transform: interpolateDom(
            [coords],
            ([x, y]) =>
              `translateX(${0 - x / 70}px) translateY(${0 - y / 70}px)`
          ),
          marginLeft: "7%",
        }}
      >
        <BodyText style={textAnimationStyle}>
          I Designed and executed computational research fully funded by the
          University of Denver's school of Natural Sciences to score the binding
          affinities and catalytic capacity of Phenylalanine Synthetase to
          aminoacylate non-canonical phenylalanine derivatives.
          <br />
          <br />
          Within the scope of the research I regularly utilized molecular
          modeling and molecular dynamic computational toolkits coupled with
          physical in vitro methods. My results made use of cluster analysis
          (Kmeans, DBSCAN, Agglomerative), dimensional reduction (PCA, TICA),
          Markov state models, kernel density estimation, statistical mechanics,
          MM(GB)SA free energy analysis, and molecular dynamic simulation.
        </BodyText>
      </TextWrapper>

      <Canvas
        resize={{ polyfill }}
        onMouseMove={onMouseMove}
        onScroll={(e) => console.log(e)}
        onWheel={onScroll}
      >
        <Scene top={top} mouse={mouse} appHeight={appHeight} />
        <Images
          top={top}
          mouse={mouse}
          openContentIndex={openContentIndex}
          appHeight={appHeight}
        />
      </Canvas>

      <div
        className="scroll-container"
        onScroll={onScroll}
        onMouseMove={onMouseMove}
        style={{ overscrollBehavior: "none" }}
      >
        <div style={{ height: "1400vh" }}></div>
      </div>

      <TextWrapper
        style={{
          top: topDom.interpolate((top) => 2 * appHeight - top),
          transform: interpolateDom(
            [coords],
            ([x, y]) =>
              `translateX(${0 - x / 70}px) translateY(${0 - y / 70}px)`
          ),
        }}
      >
        <div>
          <HeaderText style={textAnimationStyle}>
            Hello There{" "}
            <span role="img" aria-label="vulcan salute">
              🖖
            </span>
          </HeaderText>
        </div>
        <br />
        <BodyText style={textAnimationStyle}>
          My name is Austin Armstrong. I'm a software engineer in Brooklyn, with
          over 6 years of experience building consumer product in
          cross-functional teams.
          <br />
          My experience is strongest with full stack typescript projects (React,
          NestJS, & PostgreSQL).
          <br />
          Over the last year my work has focused on LLM integration for data
          classification & enrichment.
          <br />
        </BodyText>
        <br />
        <BodyText style={textAnimationStyle}>
          Continue scrolling to see some of my previous projects.
        </BodyText>
        <br />
        <br />
        <BodyText style={textAnimationStyle}>
          Want to say hi? me@austinarmstrong.dev.
        </BodyText>
      </TextWrapper>

      <TextWrapper
        style={{
          top: topDom.interpolate((top) => 14 * appHeight - top),
          transform: interpolateDom(
            [coords],
            ([x, y]) =>
              `translateX(${0 - x / 70}px) translateY(${0 - y / 70}px)`
          ),
          marginLeft: "7%",
        }}
      >
        <BodyTextTop>
          I Developed an email-based data intake pipeline that parsed & stored
          muti-vendor point of sale spreadsheet data into a central sales DB
          schema, utilizing python + cloud tooling on AWS.
          <br />
          <br />
          Data is stored on an RDS PostgreSQL instance & can be queried directly
          by financial reporting analysts.
          <br />
          An 8-page tableau dashboard was also integrated & developed to allow
          efficient reporting of sales data to the executive team.
        </BodyTextTop>
      </TextWrapper>

      <TextWrapper
        style={{
          top: topDom.interpolate((top) => 19 * appHeight - top),
          transform: interpolateDom(
            [coords],
            ([x, y]) =>
              `translateX(${0 - x / 70}px) translateY(${0 - y / 70}px)`
          ),
          marginLeft: "7%",
        }}
      >
        <BodyTextTop>
          I Designed and executed computational research fully funded by the
          University of Denver's school of Natural Sciences to score the binding
          affinities and catalytic capacity of Phenylalanine Synthetase to
          aminoacylate non-canonical phenylalanine derivatives.
          <br />
          <br />
          Within the scope of the research I regularly utilized molecular
          modeling and molecular dynamic computational toolkits coupled with
          physical in vitro methods. My results made use of cluster analysis
          (Kmeans, DBSCAN, Agglomerative), dimensional reduction (PCA, TICA),
          Markov state models, kernel density estimation, statistical mechanics,
          MM(GB)SA free energy analysis, and molecular dynamic simulation.
        </BodyTextTop>
      </TextWrapper>
    </>
  );
}